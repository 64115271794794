import {
  OwnUpBodyMedium,
  ownUpBodyMediumMixin,
  ownUpBodyMixin,
  OwnUpExtraSmallHeadlineBook
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  ListItem,
  ListProps,
  mappable,
  PrivacyBodyText,
  spacingMixin,
  TextGridItem
} from './common';
import { Divider } from './common';

// The layout of top-level list items doesn't work with
// <ol> elements. Can't have a full-length <hr> with each
// item, for example. So for top-level we roll our own.
const OrderedList1 = styled.section``;

const OrderedList2 = styled.ol`
  ${spacingMixin}

  ${ownUpBodyMediumMixin}
  list-style-type: lower-alpha;
  padding-left: ${({ theme }) => theme.spacing(4)}px;

  // This is a workaround for a bug where css classes
  // are stripped from any PrivacyBodyText that follows
  // a BulletedList during post-SSR hydration.
  > li {
    > p:first-child {
      ${ownUpBodyMediumMixin}
    }

    > p:nth-child(2) {
      margin-top: ${({ theme }) => theme.spacing(1)}px;
    }

    > p:not(:first-child) {
      ${ownUpBodyMixin}

      & > em {
        ${ownUpBodyMediumMixin}
        display: inline;
      }
    }
  }
`;

const OrderedList3 = styled.ol`
  ${spacingMixin}

  ${ownUpBodyMixin}
  list-style-type: lower-roman;
  padding-left: ${({ theme }) => theme.spacing(4)}px;
`;

const OrderedHeading1 = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpExtraSmallHeadlineBook variant="h2">{children}</OwnUpExtraSmallHeadlineBook>
);

const OrderedHeading2 = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpBodyMedium variant="body1">{children}</OwnUpBodyMedium>
);

export const OrderedList = ({ data, level = 1 }: ListProps) => {
  const listContent = data.map(({ header, content }, i) => {
    const head =
      header &&
      (level === 1 ? (
        <OrderedHeading1>
          {i + 1}. {header}
        </OrderedHeading1>
      ) : (
        <OrderedHeading2>{header}</OrderedHeading2>
      ));

    const body = mappable(content).map((stanza, idx) =>
      typeof stanza === 'string' ? (
        <PrivacyBodyText key={`body-${idx}`}>{stanza}</PrivacyBodyText>
      ) : (
        <React.Fragment key={`body-${idx}`}>{stanza}</React.Fragment>
      )
    );

    return level === 1 ? (
      <React.Fragment key={`text-grid-item-${i}`}>
        <Divider />
        <TextGridItem>{head}</TextGridItem>
        {body}
      </React.Fragment>
    ) : (
      <ListItem key={`list-item-${i}`}>
        {head}
        {body}
      </ListItem>
    );
  });

  switch (level) {
    case 1:
      return <OrderedList1>{listContent}</OrderedList1>;
      break;
    case 2:
      return <OrderedList2>{listContent}</OrderedList2>;
      break;
    case 3:
      return <OrderedList3>{listContent}</OrderedList3>;
      break;
  }
};
