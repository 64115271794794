import React from 'react';
import { PrivacyBodyText } from '../../../components/privacy/common';

export const lastUpdated = 'February 7, 2022';

export const Preface = () => (
  <PrivacyBodyText>
    This California Consumer Privacy Act Notice for California Consumers (&quot;
    <em>CCPA Notice</em>&quot;) supplements Own Up (&quot;<em>Company</em>&quot; or &quot;
    <em>Own Up</em>&quot;) Privacy Policy and Gramm-Leach-Bliley Act (&quot;<em>GLBA</em>&quot;)
    notice. This notice applies solely to California residents. The California Consumer Privacy Act
    (&quot;<em>CCPA</em>&quot;) affords California consumers certain rights over their personal
    information (subject to certain exceptions). This CCPA Notice outlines those rights and explains
    how such rights may be exercised. This CCPA Notice also summarizes our data collection and
    sharing practices, as required by the CCPA. Terms used but not defined shall have the meaning
    ascribed to them in the CCPA. For the purpose of this CCPA Notice, we refer to our websites,
    applications that run on mobile devices and tablets, call centers, and other products and
    services as &quot;<em>Services</em>&quot; or &quot;<em>Sites.</em>&quot;
  </PrivacyBodyText>
);
