import { OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import { CCPATable } from '../../../components/privacy/ccpa/table';
import { PrivacyBodyText, TextGridItem } from '../../../components/privacy/common';
import { OrderedList } from '../../../components/privacy/ordered-list';

/**
 * Content for Section 1.
 */
const whatInformationIsCovered = {
  header: 'What information is covered by this CCPA notice',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        This CCPA Notice relates to the Company&apos;s collection, use and disclosure of California
        consumers&apos; personal information. &quot;<em>Personal information</em>&quot; generally
        means information that is reasonably capable of being associated with you or your household.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 2.
 */
const whereDoWeCollectPersonalInformationFrom = {
  header: 'Where do we collect personal information from?',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        For a list of the categories of sources from which we may collect personal information,
        please see Section 5 (Summary of Collection, Use and Disclosure of Personal Information)
        below.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 3.
 */
const howDoWeUsePersonalInformation = {
  header: 'How do we use personal information?',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        We may use your personal information for a variety of &quot;business purposes&quot; and
        &quot;commercial purposes,&quot; as defined by the CCPA. &quot;<em>Business purpose</em>
        &quot; means the use of personal information for an operational or other notified purpose
        including, for example, performing services on behalf of another organization. &quot;
        <em>Commercial purpose</em>&quot; refers to when we use personal information for commercial
        or economic interests including, for example, by marketing our Services. For a detailed list
        explaining how we may use personal information, please see Section 5 (Summary of Collection,
        Use and Disclosure of Personal Information) below.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 4.
 */
const sharingInformationCCPA = [
  {
    header: 'Providers',
    content:
      'We may share your personal information with third parties we give you opportunities to interact with through the use of our Services such as when we provide you with the opportunity to obtain prequalified rates from lenders for a personal loan or to submit your loan application to a lender. The term "Provider" referenced here is defined in our Terms and Conditions. In some cases, if you click to go to a Provider\'s site, you will automatically be sending your personal information to that Provider (e.g., when clicking on an option to learn more about debt service providers, high yield savings providers or insurance providers or when applying for a credit card with one of our partner credit card issuers). Remember that any information you provide to the Provider, whether through us or on your own, will be subject to their privacy practices and terms and conditions.'
  },
  {
    header: 'Service providers',
    content: (
      <PrivacyBodyText>
        We may share your personal information with third parties who work on behalf of, or with, us
        such as vendors, processors, agents and other representatives (collectively, &quot;
        <em>Service Providers</em>&quot;). Service Providers assist us with a variety of functions.
        This includes, for example, assisting with customer service-related functions, providing
        advertising (including interest-based advertising) and analytics services (e.g., Google
        Analytics), helping us verify your identity, obtaining your credit report, providing website
        hosting services, assisting with auditing functions, and helping us with marketing and
        related research activities.
      </PrivacyBodyText>
    )
  },
  {
    header: 'Marketing partners',
    content: (
      <PrivacyBodyText>
        We may share your personal information with third-party marketing partners including
        partners who we conduct joint-marketing activities with or who we offer a co-branded service
        with (collectively, &quot;<em>Marketing Partners</em>&quot;). Marketing Partners also
        include those entities who maintain a link to our Services on their site.
      </PrivacyBodyText>
    )
  },
  {
    header: 'Affiliate companies',
    content: (
      <PrivacyBodyText>
        We may share personal information with our parent company and wholly owned subsidiaries
        (collectively, &quot;<em>Affiliate Companies</em>&quot;).
      </PrivacyBodyText>
    )
  },
  {
    header: 'Special circumstances',
    content: (
      <PrivacyBodyText>
        We may disclose your personal information to third parties: (i) where we have a good faith
        belief that such disclosure is necessary to meet any applicable law, regulation, legal
        process or other legal obligation; (ii) to protect or prevent harm or financial loss; (iii)
        to protect our legitimate interests and legal rights; and (iv) to detect, investigate and
        help prevent security, fraud or technical issues (collectively, &quot;
        <em>Special Circumstances</em>&quot;). For the purpose of this CCPA Notice, third parties
        who receive personal information in accordance with this section shall be referred to as
        &quot;<em>Special Circumstances Recipients.</em>&quot;
      </PrivacyBodyText>
    )
  },
  {
    header: 'Corporate transactions',
    content: (
      <PrivacyBodyText>
        We may transfer your personal information in the event we sell or transfer, or are
        considering selling or transferring, all or a portion of our business or assets
        (collectively, &quot;<em>Corporate Transactions</em>&quot;). For the purpose of this CCPA
        Notice, third parties who receive personal information in accordance with this section shall
        be referred to as &quot;<em>Corporate Transaction Recipients.</em>&quot;
      </PrivacyBodyText>
    )
  }
];

const whoDoWeSharePersonalInformationWith = {
  header: 'Who do we share personal information with?',
  content: [
    <TextGridItem key={1}>
      <PrivacyBodyText>
        We may share your personal information with the third parties described below. For a more
        detailed summary of who we may share your personal information with, see Section 5 (Summary
        of Collection, Use and Disclosure of Personal Information) below.
      </PrivacyBodyText>
    </TextGridItem>,
    <TextGridItem key={2}>
      <OrderedList level={2} data={sharingInformationCCPA} />
    </TextGridItem>
  ]
};

/**
 * Content for Section 5.
 */
const summaryOfCollectionUseAndDisclosure = {
  header: 'Summary of collection, use and disclosure of personal information',
  content: [
    <TextGridItem key={1}>
      <PrivacyBodyText>
        The table below summarizes our collection and sharing practices relating to personal
        information, including with respect to personal information we have collected, used and
        disclosed in the preceding 12 months. As shown below, we may have disclosed or sold your
        personal information to third parties for a business or commercial purpose in the preceding
        12 months. For all of the categories of personal information identified below, we may use
        your personal information for disclosures relating to Corporate Transactions and Special
        Circumstances. As such, all personal information may be shared with Corporate Transaction
        Recipients and Special Circumstances Recipients. Some capitalized terms used here are
        defined in the Privacy Policy above.
      </PrivacyBodyText>
    </TextGridItem>,
    <CCPATable key={2} />,
    <TextGridItem key={3}>
      <PrivacyBodyText>
        Our Site and Services are not intended for minors under 16 years of age. As such, we do not
        knowingly collect or maintain any data from minors under 16 years of age and, as such, do
        not sell personal information of minors under 16 years of age.
      </PrivacyBodyText>
    </TextGridItem>
  ]
};

/**
 * Content for Section 6.
 */
const requests = [
  { content: 'Specific pieces of personal information that the Company has about you;' },
  { content: 'Categories of personal information the Company has collected about you;' },
  { content: 'Categories of sources from which your personal information is collected;' },
  {
    content:
      'Categories of personal information that the Company has sold or disclosed for a business purpose about you;'
  },
  {
    content:
      'Categories of third parties to whom your personal information was sold or disclosed for a business purpose; and'
  },
  {
    content: 'The business or commercial purpose for collecting or selling personal information.'
  }
];

const CCPARights = [
  {
    header: 'Request to know',
    content: [
      <PrivacyBodyText key={1}>
        You have a right to request that the Company disclose what personal information it collects,
        uses, discloses, and sells. It includes a request for any or all of the following:
      </PrivacyBodyText>,
      <OrderedList key={2} level={3} data={requests} />
    ]
  },
  {
    header: 'Request to delete',
    content:
      "You have a right to request that the Company delete your personal information that we have collected from you. However, certain information regulated by other privacy and data protection laws (including, for example, the Gramm-Leach-Bliley Act) may be exempt from, or outside the scope of, any deletion request (i.e., the GLBA's requirement to retain the information may preempt your request that we delete it. In other words, we may be legally required to retain your data for a certain period of time and therefore we cannot delete your data at your request. If this happens, we will let you know in response to your request). As a result, in some instances, we may decline all or part of a deletion request if related to this type of exempt information."
  },
  {
    header: 'Request to opt-out',
    content:
      'We do not provide personal information about you to other companies for money. The advertising services we use may collect certain information, including cookies and similar information stored on your browsers, advertising identifiers on your mobile devices, and/or the IP address of your devices when you visit our sites or use our apps. Those advertising services we use may tailor and deliver ads to you on your devices. Even though we do not provide personal information to these companies for money, California law may characterize this activity by the advertising providers as "sales" of personal information. You have a right to request that these companies discontinue these targeted advertising activities.'
  },
  {
    header: 'No discrimination',
    content:
      'You have a right not to receive discriminatory treatment by the Company for the exercise of the privacy rights conferred by the CCPA.'
  }
];

const yourCCPARights = {
  header: 'Your CCPA rights',
  content: [
    <TextGridItem key={1}>
      <PrivacyBodyText>
        Subject to certain exceptions, the CCPA affords you the following rights with respect to
        your personal information. It&apos;s worth noting that these rights do not apply to personal
        information we collect about California consumers who apply for or obtain a financial
        product and service for personal, family, or household purposes (in other words, information
        regulated by the Gramm-Leach-Bliley Act). For information about how we collect, disclose and
        use information relating to these consumers and the choices relating to such information,
        please refer to our{' '}
        <OwnUpUnderlineLink to="/assets/privacy/glba-notice.pdf" component={Link}>
          GLBA Notice
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    </TextGridItem>,
    <TextGridItem key={2}>
      <OrderedList level={2} data={CCPARights} />
    </TextGridItem>
  ]
};

/**
 * Content for Section 7.
 */
const exerciseRights = [
  {
    header: 'Submitting a request to know or request to delete',
    content: (
      <PrivacyBodyText>
        You may submit a Request to Know or a Request to Delete by contacting us at this toll-free
        number, <OwnUpUnderlineLink href="tel:8777916510">(877) 791-6510</OwnUpUnderlineLink> or by
        using this interactive{' '}
        <OwnUpUnderlineLink component={Link} to="/privacy/ccpa-request">
          webform
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    )
  },
  {
    header: 'Submitting a request to opt-out',
    content: (
      <PrivacyBodyText>
        You may submit a Request to Opt-Out by contacting us at this toll-free number,{' '}
        <OwnUpUnderlineLink href="tel:8777916510">(877) 791-6510</OwnUpUnderlineLink> or by using
        this interactive{' '}
        <OwnUpUnderlineLink component={Link} to="/privacy/ccpa-request">
          webform
        </OwnUpUnderlineLink>
        . We currently do not have a method that would allow us to contact the advertising service
        providers on your behalf as we do not associate these cookies and other signals to any
        personally identifiable information we may maintain about you.
      </PrivacyBodyText>
    )
  },
  {
    header: 'Verification methods',
    content: (
      <PrivacyBodyText>
        Once you submit a request, we will verify that you are the consumer to which the request
        pertains by matching your name, email address and physical address with information we
        maintain. Depending on the type of request you submit, we will attempt to match either two
        or three of these data points to the information maintained in our systems. If we are unable
        to verify your request with the data points you provided, we may reach out to you for
        additional information to verify your request. We may also reach out to you for additional
        verifying information for requests requiring a higher degree of verification because of the
        type or sensitivity of the request.
      </PrivacyBodyText>
    )
  }
];

const howToExerciseYourCCPARights = {
  header: 'How to exercise your CCPA rights',
  content: [
    <TextGridItem key={1}>
      <OrderedList level={2} data={exerciseRights} />
    </TextGridItem>,
    <TextGridItem key={2}>
      <PrivacyBodyText>
        You may designate an authorized agent to submit a request on your behalf. We may require
        that you provide the authorized agent with written permission to act on your behalf and that
        you verify your identity directly with us.
      </PrivacyBodyText>
    </TextGridItem>
  ]
};

/**
 * Content for Section 8.
 */
const alternateFormatOfCCPANotice = {
  header: 'Alternate format of CCPA notice',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        If you have a disability that prevents or limits your ability to access this CCPA Notice,
        please email us at{' '}
        <OwnUpUnderlineLink href="mailto:support@ownup.com">support@ownup.com</OwnUpUnderlineLink>{' '}
        or call us at <OwnUpUnderlineLink href="tel:8449472848">(844) 947-2848</OwnUpUnderlineLink>.
        We will work with you to provide this notice in an alternative format.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

export const CCPASections = [
  whatInformationIsCovered,
  whereDoWeCollectPersonalInformationFrom,
  howDoWeUsePersonalInformation,
  whoDoWeSharePersonalInformationWith,
  summaryOfCollectionUseAndDisclosure,
  yourCCPARights,
  howToExerciseYourCCPARights,
  alternateFormatOfCCPANotice
];
