import { OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import { PrivacyBodyText, PrivacyBodyTextMedium } from '../../../components/privacy/common';

export const lastUpdated = 'January 16, 2023';

export const Preface = () => (
  <React.Fragment>
    <PrivacyBodyText>
      This Privacy Policy explains how RateGravity, Inc., a Delaware corporation d/b/a Own Up
      (&quot;Own Up,&quot; &quot;Company&quot;, &quot;we,&quot; &quot;our&quot; or &quot;us&quot;)
      collect, use, disclose, and protect information we collect through our online services,
      website, and also any and all communications with us via phone (including your cell-phone),
      email, text or otherwise (each, a &quot;Service&quot; and collectively, &quot;Services&quot;).
      Capitalized terms that are not defined in this Privacy Policy have the meaning given to them
      in our{' '}
      <OwnUpUnderlineLink component={Link} to="/terms-conditions">
        Terms and Conditions
      </OwnUpUnderlineLink>
      . When you use the Services, you consent to our collection, use, disclosure, and protection of
      information about you as described in this Privacy Policy.
    </PrivacyBodyText>
    <PrivacyBodyTextMedium>
      By accessing or using the services, you agree to this policy. If you do not agree to this
      policy, please do not use the service.
    </PrivacyBodyTextMedium>
    <PrivacyBodyText>
      <OwnUpUnderlineLink href="#CCPA">Notice for California Residents</OwnUpUnderlineLink>: find
      more information about your California Privacy Rights and for our Notice of Collection of
      Personal Information here.
    </PrivacyBodyText>
  </React.Fragment>
);
